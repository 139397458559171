import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, WEB_ANALYTICS_PPV_SUBCLASS_TRANSLATIONS } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "subclass-translations__list" }, [_c("div", { staticClass: "px-2" }, [_c("a-row", { staticClass: "header-container mb-3 px-4 pt-2 pb-3", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("span", { staticClass: "title" }, [_vm._v("Subclass Translations")])])], 1)], 1), _c("div", { staticClass: "card p-3 mx-5" }, [_c("div", { staticClass: "header-title" }, [_vm._v("Filter By")]), _c("a-row", { attrs: { "gutter": 24, "type": "flex", "jusify": "end", "align": "bottom" } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-filter", { attrs: { "label": "Subclass", "placeholder": "Select Subclass", "reference": "web-analytics.common.ppv-sub-class", "queries": [["CountryID", _vm.countryId]], "source": "subClass", "source-label": "subClassDesc", "value": _vm.selectedSubclass }, on: { "change": function($event) {
    return _vm.onSelectFilterChange($event, "Subclass");
  } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-filter", { attrs: { "label": "Procurement Specialist", "data-source": _vm.ppvProcurementSpecialist, "source": "procSpecId", "source-label": "procurementSpecialist", "placeholder": "Select Procurement Specialist", "value": _vm.selectedProcurement }, on: { "change": function($event) {
    return _vm.onSelectFilterChange($event, "ProcSpecID");
  } } })], 1), _c("a-col", { staticClass: "d-flex align-items-center", attrs: { "span": 6 } }, [_c("a-button", { attrs: { "icon": "close-circle" }, on: { "click": _vm.onClearFilterClick } }, [_vm._v("Clear Filter")])], 1)], 1)], 1), _c("list-composer", { attrs: { "search-by": "", "ph-search-by": "Search by ", "has-active": false, "has-filter-inactive": false, "create-button-text": "Add New" }, scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) ? { key: "customButton", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddNewClick } }, [_vm._v("Add New")])];
  }, proxy: true } : null, { key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Subclass Translations for Country")]), _c("div", { staticClass: "country-filter mr-4" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "value": _vm.selectedCountry, "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var record = ref.record;
    var edit = ref.edit;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDel(record);
    } } })], 1) : _vm._e()];
  } }], null, true) }, [_c("text-field", { key: "CountryCode", attrs: { "data-index": "countryCode", "title": "Country", "sorter": true } }), _c("text-field", { key: "SubClass", attrs: { "data-index": "subClass", "title": "Subclass", "sorter": true } }), _c("text-field", { key: "ProcurementSpecialist", attrs: { "data-index": "procurementSpecialist", "title": "Procurement Specialist", "sorter": true } })], 1)], 1);
};
var staticRenderFns$1 = [];
var SubclassTranslations_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".subclass-translations__list .country-filter[data-v-1a1bc7aa]{min-width:160px}.subclass-translations__list .header-container[data-v-1a1bc7aa]{background-color:#fff}.subclass-translations__list .header-title[data-v-1a1bc7aa]{font-style:normal;font-weight:500;font-size:16px;margin-bottom:16px;color:#272d35}.subclass-translations__list .title[data-v-1a1bc7aa]{font-style:normal;font-weight:500;font-size:20px;line-height:28px}.subclass-translations__list .card[data-v-1a1bc7aa]{background:#ffffff;border:1px solid #eaedf0}\n")();
const __vue2_script$1 = {
  name: "SubclassTranslations",
  inject: ["crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    countryId() {
      let value = this.crud.getQueryString("CountryID");
      return value ? value.value : "";
    }
  },
  created() {
    this.getCountries();
    this.getPPVProcurementSpecialist();
    this.crud.setQueryString("CountryID", this.countryIdAU);
    this.getListSubclass(this.countryId);
  },
  methods: {
    onDel(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Subclass Translation?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want this Subclass Translation?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.delete(`${this.apiUrl}/web-analytics/subclass-translations`, {
          data: {
            CountryId: record.countryId,
            SubClass: record.subClass.substring(0, record.subClass.indexOf(` `))
          }
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    },
    onAddNewClick() {
      this.$router.push("/web-analytics/subclass-translations/create");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "1a1bc7aa", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var SubclassTranslations = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-sub-class" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-procurement-specialist" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.subclass-translations", "edit-route": "/web-analytics/subclass-translations/:id", "page": _vm.page } }, [_c("subclass-translations")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    SubclassTranslations
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPV_SUBCLASS_TRANSLATIONS,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "subclass-translations",
          title: "Subclass Translations",
          path: "/web-analytics/subclass-translations"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
